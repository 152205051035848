<template>
  <portal to="layout">
    <transition name="fadeup">
      <div v-if="service" class="fixed inset-0 h-full w-full bg-black-20 backdrop-blur z-[5000] flex items-end sm:items-center ">
        <div class="absolute h-full w-full inset-0" @click="close" />
        
        <div class="bg-primary w-full sm:max-w-5xl h-[98svh] sm:h-auto sm:max-h-[90svh] overflow-y-auto scroll sm:min-h-[500px] mx-auto rounded-t-xl overflow-hidden sm:border sm:rounded sm:py-6 sm:px-2 relative">
          <button
            class="absolute top-4 right-4 z-50 bg-primary/50 rounded p-2"
            @click="close"
          >
            <b-icon icon="x" class="text-sm text-secondary" />
          </button>
          <ServiceDetails
            :id="service.id"
            :show-book-button="false"
            :show-fallback-image="false"
          />
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import ServiceDetails from '@/components/service/element/Details'

export default {
  components: {
    ServiceDetails
  },
  data () {
    return {
      service: null
    }
  },
  methods: {
    open (service) {
      if (service) {
        this.service = service
      }
    },
    close () {
      this.service = null
    }
  }
}
</script>

<style>
.fadeup-enter-active, .fadeup-leave-active {
  transition: all .5s;
}
.fadeup-enter, .fadeup-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(50px);
}
</style>