<template>
  <div class="border-b last:border-none">
    <button
      :key="service.id"
      class="relative transition-all text-left w-full"
      :class="[
        serviceIsSelected ? 'bg-action/20' : 'bg-transparent',
        isServiceSelectable ? 'hover:bg-action/20 focus:bg-action/20 cursor-pointer' : 'opacity-80',
      ]"
      :aria-selected="serviceIsSelected"
      @click.stop="toggleService(service)"
    >
      <div class="flex items-start text-secondary text-sm px-4 py-5 w-full">
        <div class="flex-shrink-0 relative mr-4">
          <template v-if="service.images">
            <b-avatar :images="service.images" :title="service.title" single size="h-8 w-8 sm:h-10 sm:w-10" class="flex-shrink-0" />

            <div v-if="serviceIsSelected" class="absolute h-6 w-6 rounded-full bg-action flex items-center justify-center text-action -top-1/4 -right-1/4">
              <span>
                {{ serviceSelections[service.id].order }}
              </span>
            </div>
          </template>

          <template v-else>
            <div
              class="h-8 w-8 sm:h-10 sm:w-10 rounded-full flex items-center justify-center"
              :class="[ serviceIsSelected ? 'bg-action text-action' : 'border border-primary' ]"
            >
              <span v-if="serviceIsSelected">
                {{ serviceSelections[service.id].order }}
              </span>
            </div>
          </template>
        </div>

        <div class="flex-grow">
          <div class="flex items-start space-x-4 justify-between">
            <div class="sm:flex items-center sm:space-x-3">
              <p class="font-semibold mb-1 sm:mb-0">
                {{ service.title }}
              </p>

              <template v-if="!isServiceSelectable">
                <span class="hidden sm:block text-secondary/60">|</span>

                <client-only>
                  <b-chip variant="primary" size="sm">
                    {{ $t('components.reservationServiceList.notBookableOnline') }}
                  </b-chip>
                </client-only>
              </template>
            </div>

            <p>
              {{ duration }}
            </p>
          </div>

          <template v-if="service.information && service.information.length">
            <template v-if="isAppLayout || isDefaultLayout">
              <p class="mt-2 line-clamp-2" v-html="formatHtml(service.information)" />
            </template>
            <template v-else>
              <p class="mt-2">
                <b-truncate  type="html" :text="formatHtml(service.information)" :length="200" />
              </p>
            </template>
          </template>
          
          <div class="flex items-center justify-between mt-2">
            <div>
              <p v-if="$site.settings.bookShowServicePrices && service.priceLabel" >
                <strong class="hidden sm:inline">{{ $t('general.price') }}:</strong> <span v-if="service.priceFrom">{{ $t('general.from') }}</span> {{ service.priceLabel }} <span v-if="service.priceHasAdditionalTaxes">{{ $t('general.plusTax') }}</span>
              </p>
            </div>

            <div v-if="isAppLayout || isDefaultLayout" class="pointer-events-none">
              <a class="text-primary pointer-events-auto" @click.stop.prevent="handleOpenServiceModal">
                More info
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="disabledWarningService == service" class="px-5">
        <b-alert show variant="warning">
          <i18n path="components.reservationServiceList.clearExisting" tag="p">
            <template #service>
              {{ service.title }}
            </template>
            <template #link>
              <a class="service-list-item--clear-button" @click.stop="clearServiceSelections">{{ $t('general.clickHere') }}</a>
            </template>
          </i18n>
        </b-alert>
      </div>

      <div v-if="noMultiWarningService == service" class="px-5">
        <b-alert show variant="warning">
          <i18n path="components.reservationServiceList.multiWarning" tag="p">
            <template #service>
              {{ service.title }}
            </template>
            <template #link>
              <a class="service-list-item--clear-button" @click.stop="clearServiceSelections">{{ $t('general.clickHere') }}</a>
            </template>
          </i18n>
        </b-alert>
      </div>

      <!--Show list if employee IS required -->
      <div v-if="forceSelectEmployee" class="text-secondary text-sm" :class="[ serviceIsSelected || forceSelectEmployeeService == service ? 'p-4' : '' ]">
        <b-alert v-if="forceSelectEmployeeService == service" show variant="warning">
          {{ $t('components.reservationServiceList.completeBelow') }}
        </b-alert>

        <div v-if="forceSelectEmployeeService == service" class="border rounded divide-y divide bg-primary bg-opacity-50">
          <a
            v-if="showAnyEmployee && (filteredEmployeeServices.length > 1 || filteredEmployeeServices.length === 0)"
            class="p-4 flex items-center justify-between space-x-4  rtl:space-x-reverse"
            :class="{ selected: serviceIsSelected && !serviceSelectedEmployee }"
            @click.stop="selectServiceEmployee(service, null)"
          >
            <div>
              <h6 class="font-semibold">
                {{ $t('components.reservationServiceList.firstAvailable') }}
              </h6>

              <div v-if="$site.settings.bookShowServicePrices && service.priceLabel" class="service-list-item--employee-force-select--price">
                <strong class="hidden sm:inline">{{ $t('general.price') }}:</strong> <span v-if="service.priceFrom">{{ $t('general.from') }}</span> {{ service.priceLabel }} <span v-if="service.priceHasAdditionalTaxes">{{ $t('general.plusTax') }}</span>
              </div>
            </div>

            <b-button variant="action" class="flex-shrink-0">
              {{ $t('general.withFirstAvailable') }} »
            </b-button>
          </a>

          <a
            v-for="employeeService in filteredEmployeeServices"
            :key="employeeService.employee.id"
            class="block p-4 sm:flex items-center justify-between sm:space-x-4 rtl:space-x-reverse"
            :class="{ selected: serviceIsSelected && serviceSelectedEmployee == employeeService.employee }"
            @click.stop="selectServiceEmployee(service, employeeService.employee)"
          >
            <div class="flex items-start space-x-4 rtl:space-x-reverse">
              <b-avatar :images="employeeService.employee.images" :title="employeeService.employee.displayName" size="h-10 w-10" class="flex-shrink-0" />

              <div>
                <h6 class="font-semibold">
                  {{ employeeService.employee.displayName }}
                </h6>
                <div class="text-xs">
                  <p>
                    <b-truncate :clamp="$t('general.showMore')" type="html" :text="formatHtml(employeeService.employee.bio)" :length="100" action-class="bookapp-action-link" />
                  </p>

                  <div v-if="$site.settings.bookShowServicePrices && employeeService.priceLabel" class="mt-2">
                    <strong class="hidden sm:inline">{{ $t('general.price') }}:</strong> <span v-if="employeeService.priceFrom">{{ $t('general.from') }}</span> {{ employeeService.priceLabel }} <span v-if="employeeService.priceHasAdditionalTaxes">{{ $t('general.plusTax') }}</span>
                  </div>
                </div>
              </div>
            </div>

            <b-button variant="action" class="w-full sm:w-auto mt-3 sm:mt-0 flex-shrink-0">
              {{ $t('general.withEmployee',{employee: employeeService.employee.firstName}) }} »
            </b-button>
          </a>
        </div>

        <div v-if="forceSelectEmployeeService != service && serviceIsSelected && serviceSelectedEmployee" class="bg-white bg-opacity-20 border border-secondary rounded">
          <a class="flex items-start space-x-4  rtl:space-x-reverse p-3" @click.stop="forceSelectEmployeeService = service">
            <b-avatar :images="serviceSelectedEmployee.images" :title="serviceSelectedEmployee.displayName" size="h-10 w-10" class="flex-shrink-0" />

            <div>
              <h6 class="font-semibold">
                {{ $t('general.withEmployee', {employee: serviceSelectedEmployee.displayName}) }}
              </h6>
              <div v-if="serviceSelectedEmployee.bio" class="text-xs">
                <p>
                  <b-truncate :clamp="$t('general.showMore')" type="html" :text="formatHtml(serviceSelectedEmployee.bio)" :length="100" action-class="bookapp-action-link" />
                </p>
              </div>
            </div>
          </a>
        </div>

        <div v-if="forceSelectEmployeeService != service && serviceIsSelected && !serviceSelectedEmployee" class="bg-white bg-opacity-30 border border-secondary rounded">
          <a class="flex items-start space-x-4  rtl:space-x-reverse p-3" @click.stop="forceSelectEmployeeService = service">
            <h6 class="mt-0">
              {{ $t('general.withFirstAvailable') }}
            </h6>
          </a>
        </div>
      </div>

      <!--Show dropdown if employee is not required -->
      <div v-if="!forceSelectEmployee && showEmployeeSelector && serviceIsSelected" class="p-4">
        <b-dropdown ref="dropdown" class="mt-2" placement="bottom-start" :auto-size="true">
          <b-dropdown-field class="w-full" :placeholder="$t('general.pleaseSelectOption')">
            <div v-if="serviceSelectedEmployee || showAnyEmployee || filteredEmployeeServices.length === 0" class="w-full flex items-center space-x-4  rtl:space-x-reverse">
              <span v-if="!serviceSelectedEmployee" v-html="$t('general.withFirstAvailableStrong')" />
              <template v-else>
                <b-avatar :images="serviceSelectedEmployee.images" :title="serviceSelectedEmployee.firstName" size="h-6 w-6" />
                <p v-html="$t('general.withEmployeeStrong',{employee: serviceSelectedEmployee.firstName})" />
              </template>
            </div>
          </b-dropdown-field>

          <template #content>
            <div class="w-full max-h-[400px] overflow-y-auto scroll">
              <b-dropdown-item
                v-if="(showAnyEmployee && filteredEmployeeServices.length > 1) || filteredEmployeeServices.length === 0"
                @click.stop="serviceEmployeeChanged(null, service)"
              >
                <strong>{{ $t('general.withFirstAvailable') }}</strong>
                <span v-if="$site.settings.bookShowServicePrices && service.priceLabel">
                  - <span v-if="service.priceFrom">from</span> {{ service.priceLabel }} <span v-if="service.priceHasAdditionalTaxes">plus tax.</span>
                </span>
              </b-dropdown-item>

              <hr v-if="showAnyEmployee && filteredEmployeeServices.length > 1" class="h-px border-0 bg-primary opacity-50 mx-6">

              <b-dropdown-item
                v-for="employeeService in filteredEmployeeServices"
                :key="employeeService.employee.id"
                class="flex items-center space-x-4  rtl:space-x-reverse"
                @click.stop="serviceEmployeeChanged(employeeService.employee, service)"
              >
                <b-avatar :images="employeeService.employee.images" :title="employeeService.employee.firstName" size="h-8 w-8" />

                <div>
                  <p class="font-semibold">
                    {{ employeeService.employee.firstName }}
                  </p>
                  <p v-if="$site.settings.bookShowServicePrices && employeeService.priceLabel" class="text-sm">
                    <span v-if="employeeService.priceFrom">{{ $t('general.from') }}</span> {{ employeeService.priceLabel }} <span v-if="employeeService.priceHasAdditionalTaxes">{{ $t('general.plusTax') }}</span>
                  </p>
                </div>
              </b-dropdown-item>
            </div>
          </template>
        </b-dropdown>
      </div>

      <div v-if="serviceIsLastSelected && (serviceSelectedEmployee || showAnyEmployee || filteredEmployeeServices.length === 0 || (!showAnyEmployee && !showEmployeeSelector))" class="py-3 px-4">
        <p class="text-xs text-secondary">
          <span v-if="allowMultipleSelections && service.customerType !== 1">
            {{ $t('components.reservationServiceList.viewAvailability', {next: nextTitle}) }}<br>
            {{ $t('components.reservationServiceList.bookMulitple') }}
          </span>
          <span v-else>
            {{ $t('components.reservationServiceList.viewAvailability', {next: nextTitle}) }}
          </span>
        </p>

        <b-button
          variant="action"
          class="flex-shrink-0 w-full mb-2 mt-4"
          :class="[ isWidgetLayout || isBotLayout || isFacebookBrowser ? 'block' : 'hidden sm:block ' ]"
          @click.stop="nextAction"
        >
          {{ nextTitle }} »
        </b-button>
      </div>
    </button>

    <!--<hr v-if="!serviceIsSelected" :key="service.id + '-hr'" class="h-px border-0 bg-secondary opacity-50 mx-6 last:hidden">-->
  </div>
</template>

<script>
import Utils from '@/utils'

import { hu, enGB, ja, es } from "date-fns/locale";

export default {
  inject: [
    'showEmployeeSelector',
    'showAnyEmployee',
    'forceSelectEmployee',
    'allowMultipleSelections',
    'serviceFilter',
    'employeeFilter',
    'disabledWarningService',
    'noMultiWarningService',
    'forceSelectEmployeeService',
    'categoryVisibilities',
    'nextTitle',
    'nextAction'
  ],
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    isWidgetLayout () {
      return this.$store.getters.layout === 'widget'
    },
    isBotLayout () {
      return this.$store.getters.layout === 'bot'
    },
    isFacebookBrowser () {
      if (typeof navigator !== 'undefined') {
        return navigator?.userAgent.includes('FBAN') || navigator?.userAgent.includes('FBAV')
      }
      
      return false
    },
    isServiceSelectable () {
      return this.service.employeeServices.length > 0
    },
    serviceSelections () {
      return this.$store.getters['booking/serviceSelections']
    },
    selectedServices () {
      return this.$store.getters['booking/selectedServices']
    },
    maxOrder () {
      return this.$store.getters['booking/maxOrder']
    },
    serviceIsLastSelected () {
      const service = this.service
      return this.serviceIsSelected && this.serviceSelections[service.id] && this.serviceSelections[service.id].order === this.maxOrder
    },
    serviceIsSelected () {
      const service = this.service
      return this.serviceSelections[service.id] && this.serviceSelections[service.id].selected
    },
    serviceIsDisabled () {
      const service = this.service
      return this.serviceSelections[service.id] && this.serviceSelections[service.id].disabled
    },
    serviceSelectedEmployee () {
      const service = this.service
      return this.serviceSelections[service.id].selectedEmployee
    },
    filteredEmployeeServices () {
      const service = this.service

      if (this.employeeFilter) {
        const employeeServices = []

        for (let i = 0; i < service.employeeServices.length; i++) {
          const employeeService = service.employeeServices[i]
          const isEmployee = employeeService.employee && employeeService.employee.type === 0
          if (!isEmployee || (this.employeeFilter && !this.employeeFilter(service, employeeService.employee))) {
            continue
          }

          employeeServices.push(employeeService)
        }

        return employeeServices
      } else {
        return service.employeeServices
      }
    },
    employeeDuration () {
      const service = this.service

      if (this.serviceSelections[service.id] && this.serviceSelections[service.id].selectedEmployee) {
        const employees = service.employeeServices
        const selectedEmployee = this.serviceSelections[service.id].selectedEmployee.id
        return employees.find(employee => employee.employee.id === selectedEmployee).customerDuration
      }

      return false
    },
    duration () {
      const locales = {
        hu,
        enGB,
        ja,
        es
      }
      const locale = this.$i18n.localeProperties.date
      const duration = this.employeeDuration || this.service.customerDuration || 0

      const hours = Math.floor(duration / 60)
      const minutes = duration % 60

      return this.$dateFns.formatDuration({
        hours,
        minutes
      }, {
        locale: locales[locale] || enGB
      })
    },
    isAppLayout () {
      return this.$site.layout === 'app'
    },
    isDefaultLayout () {
      return this.$site.layout === 'site'
    }
  },
  methods: {
    selectServiceEmployee (service, employee) {
      this.forceSelectEmployeeService = null

      this.$store.dispatch('booking/setServiceSelected', { service, employee, selected: true })
        .then(() => {
          this.$emit('change')
        })
    },
    serviceEmployeeChanged (employee, service) {
      this.$store.dispatch('booking/setServiceSelected', { selected: true, service, employee })
        .then(() => {
          this.$emit('change')
        })

      this.$refs.dropdown.close()
    },
    toggleService () {
      if (!this.isServiceSelectable) {
        return
      }

      const service = this.service

      const disabled = this.serviceIsDisabled
      const selected = this.serviceIsSelected

      if (!selected && this.forceSelectEmployee && this.forceSelectEmployeeService === service) {
        this.forceSelectEmployeeService = null

        return
      }

      this.disabledWarningService = null
      this.noMultiWarningService = null
      this.forceSelectEmployeeService = null

      if (disabled) {
        this.disabledWarningService = service
        return
      }

      if (service && this.selectedServices.length > 0 && !this.allowMultipleSelections) {
        if (this.selectedServices[0].service.id !== service.id) {
          this.noMultiWarningService = service
          return
        }
      }

      if (!selected && this.forceSelectEmployee) {
        const employees = this.filteredEmployeeServices

        if (employees.length > 1) {
          this.forceSelectEmployeeService = service
          return
        }
      }

      let employee = null

      if (service) {
        const employees = this.filteredEmployeeServices

        if (employees.length === 1) {
          employee = employees[0].employee
        }
      }
      this.$store.dispatch('booking/setServiceSelected', { service, employee, selected: !selected, toBack: true })
        .then(() => {
          this.$emit('change')
        })
    },
    async clearServiceSelections () {
      const service = this.service

      this.disabledWarningService = null
      this.noMultiWarningService = null
      this.forceSelectEmployeeService = null

      await this.$store.dispatch('booking/clearServiceSelections')

      if (service) {
        this.toggleService(service)
      }
    },
    formatHtml (text) {
      return Utils.text.formatHtml(text)
    },
    handleOpenServiceModal () {
      this.$emit('openServiceModal', this.service)
    }
  }
}
</script>
