<template>
  <b-layout :colors="$site.colors" :header-title="servicesTitle" :header-back-route="homeRoute">
    <b-container class="mt-12">
      <b-summary>
        <template #summary>
          <b-card :title="$t('pages.book.index.selection')" :class="moveReservation || isBotLayout || isWidgetLayout ? 'pt-6 block' : 'hidden sm:block'">
            <move-detail />

            <div v-if="selectedServices.length > 0">
              <div>
                <div v-for="(serviceSelection, index) in selectedServices" :key="`slotService-`+ index" class="flex items-start justify-between text-sm text-secondary mb-2">
                  <div>
                    <p class="font-semibold">
                      {{ serviceSelection.service.title }}
                    </p>
                    <p>
                      <template v-if="serviceSelection.selectedEmployee">
                        {{ $t('general.withEmployee',{employee: serviceSelection.selectedEmployee.firstName}) }}
                      </template>
                      <template v-else>
                        {{ $t('general.withAny') }}
                      </template>
                    </p>
                  </div>

                  <div>
                    <p v-if="$site.settings.bookShowServicePrices && priceLabelForServiceSelection(serviceSelection)">
                      {{ priceLabelForServiceSelection(serviceSelection) }}
                    </p>
                  </div>
                </div>
              </div>

              <p class="text-secondary text-sm mt-12 opacity-80">
                <span v-if="(selectedServices[0].service.customerType == 1 || !$site.settings.allowMultiService)">
                  {{ $t('pages.book.index.nextToSeeAvailability') }}
                </span>
                <span v-else>
                  {{ $t('pages.book.index.bookMultipleServices') }}
                </span>
              </p>
            </div>

            <p v-else class="text-secondary text-sm">
              <span v-if="$site.settings.allowMultiService">
                {{ $t('pages.book.index.selectAtLeastOne') }}
              </span>
              <span v-else>
                {{ $t('pages.book.index.selectAService') }}
              </span>
            </p>

            <template #action>
              <div
                class="w-full relative z-30 px-0 mt-12 py-0 bg-transparent"
                :class="[
                  isNextDisabled ? 'hidden sm:block' : ''
                ]"
              >
                <b-button
                  :name="$t('general.next')"
                  class="w-full"
                  size="lg"
                  variant="action"
                  :disabled="isNextDisabled"
                  @click="next"
                >
                  {{ $t('general.next') }}
                </b-button>
              </div>
            </template>
          </b-card>

          <portal to="layout" :disabled="isBotLayout || isWidgetLayout || isNextDisabled">
            <div
              v-if="!isBotLayout && !isWidgetLayout && selectedServices.length > 0"
              class="block sm:hidden w-full px-4 sm:px-0 bg-primary border-t py-4 border-b sm:border-none sm:py-0 sm:bg-transparent"
            >
              <div class="text-sm flex items-center justify-between mb-3 pb-3 border-b">
                <p class="text-secondary/80">{{ selectedServices.length }} service{{ selectedServices.length > 1 ? 's' : '' }} selected</p>
                <p class="text-secondary/80 font-medium">{{ priceLabel }}</p>
              </div>
              <b-button
                :name="$t('general.next')"
                class="w-full"
                variant="action"
                :disabled="isNextDisabled"
                @click="next"
              >
                {{ $t('general.next') }}
              </b-button>
            </div>
          </portal>
        </template>
        <div>
          <voucher-alert />

          <services-list
            :service-filter="serviceListFilter"
            :employee-filter="serviceListEmployeeFilter"
            :show-any-employee="$site.settings.bookShowFirstAvailable"
            :force-select-employee="$site.settings.allowSelectEmployee == 2"
            :show-employee-selector="$site.settings.allowSelectEmployee > 0"
            :allow-multiple-selections="$site.settings.allowMultiService"
            :next-action="next"
            :next-title="$t('general.next')"
            @change="updateUrl"
          />
        </div>
      </b-summary>
    </b-container>
  </b-layout>
</template>

<script>
import LayoutMixin from '@/mixins/layout'
import ServicesList from '@/components/booking/elements/Services.vue'
import VoucherAlert from '@/components/voucher/elements/Alert.vue'
import MoveDetail from '@/components/booking/elements/MoveDetail.vue'
import { BookingStage, createBookingRoute } from '@/components/booking/elements/Detail.vue'

export default {
  name: 'ReservationHome',
  components: {
    ServicesList,
    VoucherAlert,
    MoveDetail
  },
  filters: {
    employeeName (employee) {
      if (!employee) { return 'Any' }
      return employee.firstName
    }
  },
  mixins: [LayoutMixin],
  middleware: ['location', 'redirects'],
  meta: {
    tabs: ['book']
  },
  data () {
    return {
      serviceFilter: null,
      moveReservation: null
    }
  },
  fetch ({ store, params, route }) {
    return Promise.all([
      store.dispatch('location/getServices'),
      store.dispatch('booking/setFromUrlQuery', route.query)
    ])
  },
  computed: {
    title () {
      return this.$site.tabs.getName('book', 'Book')
    },
    info () {
      return this.$site.tabs.getInfo('book')
    },
    isBotLayout () {
      return this.$store.getters.layout === 'bot'
    },
    isWidgetLayout () {
      return this.$store.getters.layout === 'widget'
    },
    isNextDisabled () {
      return this.selectedServices.length === 0
        || (!this.$site.settings.bookShowFirstAvailable && !this.servicesSelectedEmployee && this.$site.settings.allowSelectEmployee > 0)
    },
    homeRoute () {
      return this.$store.getters.homeRoute || { path: '/' }
    },
    servicesTitle () {
      if (this.filters.category) {
        const services = this.services
        for (let i = 0; i < services.categories.length; i++) {
          const category = services.categories[i]
          if (category.id === this.filters.category) {
            return category.name
          }
        }
      }
      return this.$t('pages.book.index.services')
    },
    selectedServices () {
      return this.$store.getters['booking/selectedServices']
    },
    services () {
      return this.$store.getters['location/services']
    },
    filters () {
      return this.$store.getters['booking/filters']
    },
    voucher () {
      return this.$store.getters['booking/voucher']
    },
    servicesSelectedEmployee () {
      const selectedServices = this.selectedServices

      for (const serviceSelection of selectedServices) {
        if (!serviceSelection.selectedEmployee) {
          const service = serviceSelection.service
          const employeeServices = []

          for (let i = 0; i < service.employeeServices.length; i++) {
            const employeeService = service.employeeServices[i]
            const isEmployee = employeeService.employee && employeeService.employee.type === 0
            if (!isEmployee || (this.employeeFilter && !this.employeeFilter(service, employeeService.employee))) {
              continue
            }

            employeeServices.push(employeeService)
          }

          if (employeeServices.length !== 0) {
            return false
          }
        }
      }

      return true
    },
    priceLabel () {
      const currency = this.$site.location.currencySymbol
      const selectedServices = this.selectedServices
      let total = 0
      let from = false

      for (const serviceSelection of selectedServices) {
        if (serviceSelection.selectedEmployee && serviceSelection.service && serviceSelection.service.employeeServices) {
          for (let i = 0; i < serviceSelection.service.employeeServices.length; ++i) {
            if (serviceSelection.service.employeeServices[i].employee.id === serviceSelection.selectedEmployee.id) {
              total += serviceSelection.service.employeeServices[i].price
              if (serviceSelection.service.employeeServices[i].priceFrom) {
                from = true
              }
            }
          }
        } else {
          total += serviceSelection.service.price
          if (serviceSelection.service.priceFrom) {
            from = true
          }
        }
      }

      return (from ? 'from ' : '') + currency + total
    }
  },
  watch: {
    $route () {
      this.$store.dispatch('booking/setFromUrlQuery', this.$route.query)
    }
  },
  mounted () {
    this.moveReservation = this.$store.getters['booking/moveReservation']
  },
  methods: {
    serviceListFilter (service, category) {
      if (this.filters.service) {
        const services = this.filters.service.split(',').map(x => parseInt(x))

        if (services.includes(service.id)) {
          return true
        }

        return false
      }

      if (this.filters.category) {
        const categories = this.filters.category.split(',').map(x => parseInt(x))

        if (categories.includes(0)) {
          if (!category.id) {
            return true
          }
        }

        if (categories.includes(category.id)) {
          return true
        }

        return false
      }

      if (this.voucher && this.voucher.isValid && this.voucher.validity && this.voucher.validity.services) {
        return this.voucher.validity.services.find(validService => validService.id === service.id)
      }

      return true
    },
    serviceListEmployeeFilter (service, employee) {
      if (this.voucher && this.voucher.isValid && this.voucher.validity && this.voucher.validity.employees) {
        return this.voucher.validity.employees.find(validEmployee => validEmployee.id === employee.id)
      }

      return true
    },
    next () {
      const bookingUrlQuery = this.$store.getters['booking/urlQuery']

      this.$router.push(createBookingRoute(bookingUrlQuery, BookingStage.AVAILABILITY))
    },
    updateUrl () {
      const bookingUrlQuery = this.$store.getters['booking/urlQuery']
      const route = createBookingRoute(bookingUrlQuery, BookingStage.SERVICES)

      this.$router.push({ query: route.query })
    },
    removeService (service) {
      this.$store.dispatch('booking/setServiceSelected', { service, selected: false })
      this.updateUrl()
    },
    priceLabelForServiceSelection (serviceSelection) {
      if (serviceSelection.selectedEmployee && serviceSelection.service && serviceSelection.service.employeeServices) {
        for (let i = 0; i < serviceSelection.service.employeeServices.length; ++i) {
          if (serviceSelection.service.employeeServices[i].employee.id === serviceSelection.selectedEmployee.id) {
            return (serviceSelection.service.employeeServices[i].priceFrom ? 'from ' : '') + serviceSelection.service.employeeServices[i].priceLabel
          }
        }
        return ''
      } else {
        return (serviceSelection.service.priceFrom ? 'from ' : '') + serviceSelection.service.priceLabel
      }
    }
  }
}
</script>

<style scoped>
.confirmation-details--services--service
{
  font-weight: bold;
  font-size: 16px;
}

.confirmation-details--services--employee
{
  color: var(--bookapp-content-text-color) !important;
  font-size: 13px;
}
.confirmation-details--services--price
{
  font-size: 14px;
}
</style>
