<template>
  <div>
    <div v-if="showSearch" class="search mb-4">
      <b-input-group>
        <b-text-input v-model="search" size="lg" :placeholder="$t('components.reservationServiceList.whatService')" class="">
          <template #leading>
            <span class="pr-3 text-secondary">
              <b-icon icon="search" />
            </span>
          </template>
        </b-text-input>
      </b-input-group>
    </div>

    <b-card v-if="!filteredServices.categories.length" class="mb-4  search-not-found">
      {{ $t('components.reservationServiceList.cantFindService') }}
    </b-card>

    <category
      v-for="category in filteredServices.categories"
      :key="category.name"
      :category="category"
      :visible="categoryIsVisibile(category, filteredServices.categories)"
      @show="setCategoryVisibility(category, true)"
      @toggle="toggleCategoryVisibility(category)"
      @openServiceModal="handleOpenServiceModal"
    />

    <service-modal ref="serviceModal" />
  </div>
</template>

<script>
import Category from './Category'
import ServiceModal from './ServiceModal'

export default {
  components: {
    Category,
    ServiceModal
  },
  provide () {
    const {
      showEmployeeSelector,
      showAnyEmployee,
      forceSelectEmployee,
      allowMultipleSelections,
      serviceFilter,
      employeeFilter,
      disabledWarningService,
      noMultiWarningService,
      forceSelectEmployeeService,
      categoryVisibilities,
      nextTitle,
      nextAction
    } = this

    return {
      showEmployeeSelector,
      showAnyEmployee,
      forceSelectEmployee,
      allowMultipleSelections,
      serviceFilter,
      employeeFilter,
      disabledWarningService,
      noMultiWarningService,
      forceSelectEmployeeService,
      categoryVisibilities,
      nextTitle,
      nextAction
    }
  },
  props: {
    showEmployeeSelector: {
      type: Boolean,
      default: true
    },
    showAnyEmployee: {
      type: Boolean,
      default: true
    },
    forceSelectEmployee: {
      type: Boolean,
      default: false
    },
    allowMultipleSelections: {
      type: Boolean,
      default: true
    },
    serviceFilter: {
      type: Function,
      default: null
    },
    employeeFilter: {
      type: Function,
      default: null
    },
    nextAction: {
      type: Function,
      default: () => {}
    },
    nextTitle: {
      type: String,
      default: 'Next'
    }
  },
  data () {
    return {
      search: null,
      disabledWarningService: null,
      noMultiWarningService: null,
      forceSelectEmployeeService: null,
      categoryVisibilities: [null]
    }
  },
  computed: {
    showSearch () {
      if (this.search !== null && this.search.length > 0) { return true }

      const filteredServices = this.filteredServices

      let count = 0
      for (let i = 0; i < filteredServices.categories.length; i++) {
        count += filteredServices.categories[i].services.length

        if (count > 5) { return true }
      }

      return false
    },
    serviceSelections () {
      return this.$store.getters['booking/serviceSelections']
    },
    services () {
      return this.$store.getters['location/services']
    },
    filteredServices () {
      const allServices = this.$store.getters['location/services']

      if (this.serviceFilter || this.search) {
        const services = {
          categories: []
        }
        for (let i = 0; i < allServices.categories.length; i++) {
          const category = allServices.categories[i]

          const categoryServices = []

          for (let j = 0; j < allServices.categories[i].services.length; j++) {
            const service = allServices.categories[i].services[j]

            if (this.serviceFilter && !this.serviceFilter(service, category)) {
              continue
            }
            if (this.search && !this.serviceMatchesSearch(service, category)) {
              continue
            }
            /* if (service.employeeServices.length === 0) {
              continue
            } */

            categoryServices.push(service)
          }

          if (categoryServices.length > 0) {
            services.categories.push({ ...category, services: categoryServices })
          }
        }
        return services
      } else {
        return allServices
      }
    }
  },
  created () {
    for (const category of this.services.categories) {
      for (const service of category.services) {
        if (this.serviceIsSelected(service)) {
          this.setCategoryVisibility(category, true)
        }
      }
    }

    if (this.services.categories.length === 1) {
      this.setCategoryVisibility(this.services.categories[0], true)
    }
  },
  methods: {
    setCategoryVisibility (category, visible) {
      if (!visible && this.categoryVisibilities.includes(category.id)) {
        const index = this.categoryVisibilities.indexOf(category.id)
        if (index !== -1) { this.categoryVisibilities.splice(index, 1) }
      }

      if (visible && !this.categoryVisibilities.includes(category.id)) {
        this.categoryVisibilities.push(category.id)
      }
    },
    toggleCategoryVisibility (category) {
      if (this.categoryVisibilities.includes(category.id)) {
        const index = this.categoryVisibilities.indexOf(category.id)
        if (index !== -1) { this.categoryVisibilities.splice(index, 1) }
      } else {
        this.categoryVisibilities.push(category.id)
      }
    },
    categoryIsVisibile (category, categories) {
      return ((this.search !== null && this.search.length > 0) || this.categoryVisibilities.includes(category.id) || !this.categoryCanToggleVisibility(category, categories))
    },
    categoryCanToggleVisibility (category, categories) {
      return (category.services.length > 1 || categories.length > 1)
    },
    serviceIsSelected (service) {
      return (this.serviceSelections[service.id] && this.serviceSelections[service.id].selected)
    },
    serviceMatchesSearch (service, category) {
      const query = this.search.toLowerCase()
      const parts = query.split(' ')

      for (let i = 0; i < parts.length; i++) {
        let found = false

        if (service.title && service.title.toLowerCase().includes(parts[i])) { found = true }
        if (service.information && service.information.toLowerCase().includes(parts[i])) { found = true }
        if (category.name && category.name.toLowerCase().includes(parts[i])) { found = true }

        if (!found) { return false }
      }

      return true
    },
    handleOpenServiceModal (service) {
      this.$refs.serviceModal.open(service)
    }
  }
}
</script>
