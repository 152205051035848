<template>
  <div>
    <div v-if="loading" class="w-full flex justify-center items-center py-12">
      <b-spinner label="Loading" size="h-8 w-8" type="grow" />
    </div>

    <b-container v-else-if="service">
      <div v-if="service.images || (showFallbackImage && $site.images.hero)" class="-mx-4 bg-gray-500 sm:hidden mb-6 overflow-hidden">
        <img class="h-full w-full " :src="service.images ? service.images.large : $site.images.hero.large">
      </div>
      
      <h4
        class="text-primary text-xl sm:text-3xl font-semibold"
        :class="(showFallbackImage && $site.images.hero) ? 'mt-0' : 'mt-4 sm:mt-0'"
      >
        {{ service.title }}
      </h4>
      <b-chip
        v-if="service.category"
        class="mb-4 mt-2"
        variant="primary"
        size="lg"
        soft
      >
        {{ service.category.name }}
      </b-chip>


      <portal v-if="showBookButton && service.allowInternet" to="layout">
        <div class="block sm:hidden w-full px-3 sm:px-0 bg-primary border-t py-4 sm:border-none sm:py-0 sm:bg-transparent">
          <b-button class="sm:hidden w-full" variant="action" :to="bookingURL">
            {{ $t('general.bookNow') }}
          </b-button>
        </div>
      </portal>

      <div class="sm:flex gap-10">
        <div v-if="service.images || $site.images.hero" class="w-2/3 h-[500px] hidden sm:block rounded overflow-hidden relative">
          <img class="h-full w-full object-cover object-center" :src="service.images ? service.images.large : $site.images.hero.large">
        </div>
        <div class="text-sm flex-1 flex flex-col justify-between sm:p-4 sm:rounded sm:border sm:bg-primary text-secondary">
          <div>
            <div class="grid grid-cols-2 gap-4">
              <div v-if="$site.settings.bookShowServicePrices && service.price" class="mb-6">
                <p class="mb-1 font-semibold">
                  {{ $t('general.price') }}
                </p>
                <p class="text-lg text-primary font-semibold">
                  <span v-if="service.priceFrom">{{ $t('general.from') }}</span> {{ service.priceLabel }} <span v-if="service.priceHasAdditionalTaxes">{{ $t('general.plusTax') }}</span>
                </p>
              </div>

              <div v-if="service.duration" class="mb-6">
                <p class="mb-1 font-semibold">
                  {{ $t('pages.service.detail.duration') }}
                </p>
                <p class="text-lg text-primary font-semibold">
                  {{ service.duration }} {{ $t('datetime.increments.minutes') }}
                </p>
              </div>
            </div>

            <div v-if="service.information && service.information.length < 500" class="pb-6">
              <p class="mb-1 font-semibold">
                About service
              </p>
              <p class="mb-4 text-secondary/80" v-html="formatHtml(service.information)" />
            </div>
          </div>
          <div>
            <div
              v-if="service.reviewRating"
              class="border-secondary border-t-1 pt-4 flex items-center space-x-2"
              :class="showBookButton && service.allowInternet ? 'border-b-1 pb-4 mb-4' : ''"
            >
              <p class="text-primary text-xl">
                <b-icon icon="review" solid />
              </p>
              <p>
                {{ $t('pages.service.detail.stars', {stars: service.reviewRating.toFixed(2)}) }} <span class="opacity-75">({{ $tc('pages.service.detail.ratedBy', service.reviewCount, { people: service.reviewCount }) }})</span>
              </p>
            </div>

            <b-button v-if="showBookButton && service.allowInternet" variant="action" class="w-full" :to="bookingURL">
              {{ $t('general.bookNow') }}
            </b-button>
          </div>
        </div>
      </div>
      <div class="mt-12 text-sm">
        <div v-if="service.information && service.information.length >= 500" class="pb-4 text-secondary">
          <p class="mb-1 font-semibold">
            About service
          </p>
          <p class="mb-4 text-secondary/80" v-html="formatHtml(service.information)" />
        </div>

        <div v-if="service.customFields && service.customFields.length">
          <template v-for="customField in service.customFields">
            <div v-if="customField.valueLabel && customField.customerVisible" class="pb-4">
              <p class="mb-1 font-semibold text-secondary">
                {{ customField.name }}
              </p>
              <p class="mb-4 text-secondary/80" v-html="customField.valueLabel" />
            </div>
          </template>
        </div>

        <div v-if="employees">
          <div class="mb-4">
            <h5 class="text-sm text-secondary/50">
              {{ $t('pages.service.detail.availableWith') }}
            </h5>
            <div v-if="employees" class="flex gap-4 mt-2 flex-wrap">
              <div v-for="(employee, index) in employees" :key="index" class="flex items-center gap-2 text-primary py-1 px-1.5 rounded border bg-primary text-sm">
                <!--<img v-if="employee.hasImage" class="rounded-[50%] h-8" :src="employee.images.square" :alt="employee.displayName">-->
                <b-avatar :images="employee.images" :title="employee.displayName" single size="h-8 w-8" />
                <p>{{ employee.displayName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Utils from '@/utils'

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    showBookButton: {
      type: Boolean,
      default: true
    },
    showFallbackImage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: true,
      service: null,
      employees: null
    }
  },
  computed: {
    bookingURL () {
      const serviceData = [
        { s: this.service.id }
      ]

      const services = Buffer.from(JSON.stringify(serviceData)).toString('base64')

      return `/book-now/availability?services=${services}`
    }
  },
  mounted () {
    this.fetchService()
  },
  methods: {
    async fetchService () {
      const id = this.id
      const serviceRes = await this.$api.location.service(id)
      const service = serviceRes.service
      this.service = service

      const employeesRes = await this.$api.location.employees(
        service.employeeIDs
      )
      this.employees = employeesRes.employees

      this.loading = false
    },
    formatHtml (text) {
      return Utils.text.formatHtml(text)
    },
  }
}
</script>
