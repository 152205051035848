<template>
  <b-alert
    v-if="voucher"
    show
    :variant="(voucher.isValid) ? 'secondary' : 'danger'"
    class="mb-6"
  >
    <div class="w-full">
      <div class="flex items-center justify-between w-full mb-4">
        <p>{{ $t('general.voucher') }}: <strong>{{ voucher.code }}</strong></p>

        <a @click="removeVoucher"><b-icon icon="x" class="text-secondary" /></a>
      </div>

      <div v-if="voucher.isValid">
        <p v-if="voucher.information" class="mb-2" v-html="formatHtml(voucher.information)" />
        <small>{{ $t('components.voucherDetail.showingValid') }}</small>
      </div>

      <b-button size="sm" variant="action" class="mt-6" @click="removeVoucher">
        {{ $t('components.voucherDetail.remove') }}
      </b-button>

      <div v-if="!voucher.isValid">
        <p>
          <ul class="list-unstyled">
            <li v-for="error in voucher.errors" :key="error">
              {{ error }}
            </li>
          </ul>
        </p>
      </div>
    </div>
  </b-alert>
</template>

<script>
import Utils from '@/utils'

export default {
  computed: {
    voucher () {
      return this.$store.getters['booking/voucher']
    }
  },
  methods: {
    formatHtml (text) {
      return Utils.text.formatHtml(text)
    },
    removeVoucher () {
      this.$store.dispatch('booking/setVoucher', null)

      const query = Object.assign({}, this.$route.query)
      delete query.voucher
      this.$router.replace({ query })
    }
  }
}
</script>
