<template>
  <b-card
    :title="category.name"
    :visible="visible"
    :header-class="[
      'flex items-center justify-between p-3 sm:p-4 hover:bg-primary/30 cursor-pointer transition-all  w-full text-left border bg-primary rounded-t ',
      visible ? 'shadow-sm' : 'rounded'
    ]"
    content-class="w-full flex flex-col justify-between sm:p-4 rounded-b border border-t-0 sm:bg-primary"
    class="mb-2"
    @click.stop="handleToggle"
  >
    <template #image>
      <b-avatar :images="category.images" :title="category.name" single class="mr-4" size="h-10 w-10 sm:h-12 sm:w-12" />
    </template>

    <template #subtitle>
      <b-truncate v-if="category.information && category.information.length" :clamp="$t('general.showMore')" type="html" :text="formatHtml(category.information)" :length="200" />
    </template>

    <template #control>
      <b-icon icon="chevronDown" class="h-5 w-5 ml-4 sm:ml-6 sm:h-7 sm:w-7 text-secondary transition-transform duration-300 flex-shrink-0" :class="visible ? 'rotate-180' : ''" />
    </template>

    <div class="bg-primary/30 sm:bg-secondary sm:rounded sm:border overflow-hidden">
      <service
      v-for="service in category.services"
      :key="service.id"
      :service="service"
      @change="handleChange"
      @openServiceModal="handleOpenServiceModal"
      />
    </div>
  </b-card>
</template>

<script>
import Service from './Service'

import Utils from '@/utils'

export default {
  components: {
    Service
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleShow () {
      this.$emit('show')
    },
    handleToggle () {
      this.$emit('toggle')
    },
    handleChange () {
      this.$emit('change')
    },
    handleOpenServiceModal (service) {
      this.$emit('openServiceModal', service)
    },
    formatHtml (text) {
      return Utils.text.formatHtml(text)
    }
  }
}
</script>
